import { ListObject } from "./models"

export const getBedList = () => [
  new ListObject("hj001", "HJ001", "http://static.mpebedding.com/HJ001.mp4"),
  new ListObject("hj002", "HJ002", "http://static.mpebedding.com/HJ002.mp4"),
  new ListObject("hj006", "HJ006", "http://static.mpebedding.com/HJ006.mp4"),
  new ListObject("b201", "B201", "http://static.mpebedding.com/B201.mp4"),
  new ListObject("b202", "B202", "http://static.mpebedding.com/B202.mp4"),
  new ListObject("b301", "B301", "http://static.mpebedding.com/B301.mp4"),
  new ListObject("b305", "B305", "http://static.mpebedding.com/B305.mp4"),
  new ListObject("b306", "B306", "http://static.mpebedding.com/B306.mp4"),
  new ListObject("hc433", "HC433", "http://static.mpebedding.com/HC433.mp4"),
  new ListObject(
    "hc433_huawei",
    "HC433华为款",
    "http://static.mpebedding.com/HC433华为.mp4"
  )
]

export const getChairList = () => [
  new ListObject("hj008", "HJ008", "http://static.mpebedding.com/HJ008.mp4"),
  new ListObject("c301", "C301", "http://static.mpebedding.com/C301.mp4"),
  new ListObject("c305", "C305", "http://static.mpebedding.com/C305.mp4"),
  new ListObject(
    "hj008_huawei",
    "HJ008华为款",
    "http://static.mpebedding.com/HJ008华为.mp4"
  )
]

export const getOtherList = () => [
  new ListObject(
    "sleepace",
    "睡眠健康助手",
    "http://static.mpebedding.com/%E7%9D%A1%E7%9C%A0%E5%B8%A6%E8%BF%9E%E6%8E%A5%E6%95%99%E7%A8%8B2.mp4"
  )
]

export const getList = () => [
  ...getBedList(),
  ...getChairList(),
  ...getOtherList()
]
