
import { defineComponent } from "vue"
import { useRouter } from "vue-router"
import { getBedList, getChairList, getOtherList } from "./script/constants"
export default defineComponent({
  setup() {
    const bedList = getBedList()
    const chairList = getChairList()
    const otherList = getOtherList()
    const router = useRouter()
    const to = (key: string) => {
      router.push(`/instructionalVideo/${key}`)
    }
    return {
      bedList,
      chairList,
      otherList,
      to
    }
  }
})
