export class ListObject {
  key: string
  name: string
  url: string
  constructor(key: string, name: string, url: string) {
    this.key = key
    this.name = name
    this.url = url
  }
}
